import React from 'react'
import focused from "../../assets/Images/focused-call-center-operators-working-process.svg"
import "./services.scss"
import businesmanW from "../../assets/Images/side-big-image2.jpg"
import businesmanM from "../../assets/Images/side-big-image3.jpg"

export default function Distributors() {
    return (
        <section className="Distributor_section" id="distributor">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 text-center" data-aos="flip-left">
                        <img
                            src={businesmanW}
                            className="img-fluid girl-comunity img-thumbnail"
                            alt="community image"
                            style={{ width: "450px", height: "400px", objectFit: "cover" }}
                        />
                    </div>

                    <div className="col-lg-7" data-aos="fade-right">
                        <h2>Become a Distributor</h2>
                        <p className='section-peragrph'>Empower your entrepreneurial journey by collaborating with  GMpay as a distributor. As a distributor, you play a pivotal role in extending our financial services reach. Enjoy the benefits of advanced technology, robust support, and attractive commission structures.</p>
                        <div className='why-choose-mob'>
                            <h3>Why Choose Distributorship with GMPay India ?</h3>
                            <p className='section-peragrph'>Comprehensive Service Portfolio: Access a wide range of financial services. Cutting-edge Technology: Utilize advanced infrastructure for seamless and secure transactions. Lucrative Commissions: Rewarding structures acknowledging efforts in financial inclusion. Dedicated Support: Get assistance for success and query resolutions.</p>
                            <a href='https://b2b.gmpayindia.com/registration' className='register-btn'>register</a>
                        </div>
                    </div>

                </div>

                <div className="row Distributor_second_row">
                    <div className="col-lg-7" data-aos="fade-right">
                        <h4>Elevate Your Role</h4>
                        <h2>
                            <span className="heading-style"> Master Distributorship </span>
                            <span className='text-dark'>Opportunities</span>
                        </h2>
                        <p className='section-peragrph'>Master Distributors are integral to our network, overseeing territories and distributors. This role offers added responsibilities and exclusive benefits.</p>
                        <div className='why-choose-mob'>
                            <h3>Why Choose Distributorship with GMPay India ?</h3>
                            <p className='section-peragrph'>Territorial Leadership: Lead financial service expansion and drive growth in designated regions. Exclusive Territory Rights: Maximize potential earnings with exclusive territorial rights. Enhanced Commissions: Enjoy superior commission structures for pivotal roles in our network. Training and Development: Access specialized training to enhance leadership and management skills.</p>
                            <a href='https://b2b.gmpayindia.com/registration' className='register-btn'>register</a>
                        </div>
                    </div>

                    <div className="col-lg-5 text-center" data-aos="flip-right">
                        <img
                            src={businesmanM}
                            className="img-fluid girl-comunity img-thumbnail distributer-img-mob"
                            alt="community image"
                            style={{ width: "450px", height: "400px", objectFit: "cover" }}
                        />
                    </div>



                </div>

            </div>
        </section>
    )
}
