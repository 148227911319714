import React from 'react'
import "./services.scss"
import Distributors from './Distributors'
// import ReactDOM from 'react-dom';
import MyCarousel from './MyCarousel'

export default function Services() {
    return (
        <>
            <section className='service-section' id="service">
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 title-div text-center' data-aos="fade-up">
                            <h1 className='section-heading'>
                                Our Array of Services
                            </h1>
                            <p className='section-peragrph'>
                                Insurance Premium
                                Payments
                                Effortlessly pay insurance premiums
                                and access general insurance services.
                            </p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <MyCarousel />
                        </div>
                    </div>
                </div>
            </section>

            <Distributors />
        </>
    )
}
