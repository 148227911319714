import Home from "./Pages/Home/Home";
// import Network from "./Components/Network/Network";
// import Services from "./Components/Services/Services";
// import Contact from "./Components/Contact/Contact";
import AOS from 'aos';
import 'aos/dist/aos.css';
import PublicLayout from "./Components/Routes/PublicLayout";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import TermsAndCondition from "./Pages/TermsAndCondition/TermsAndCondition";
import Privacypolicy from "./Pages/Privacy/Privacypolicy";
import Walletpolicy from "./Pages/Wallet/Walletpolicy";
import Grievancepolicy from "./Pages/Grievance/Grievancepolicy";
import UserLicense from "./Pages/UserLicense/UserLicense";
import { useEffect } from "react";

function App() {
  
  useEffect(() => {
    AOS.init({
      duration: 1000, // Set animation duration to 1000ms
    });
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<PublicLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/terms" element={<TermsAndCondition />} />
        <Route path="/privacy" element={<Privacypolicy />} />
        <Route path="/Wallet" element={<Walletpolicy />} />
        <Route path="/Grievance" element={<Grievancepolicy />} />
        <Route path="/License" element={<UserLicense />} />
      </Route>
    )
  );
  return <RouterProvider router={router} />;
}

export default App;
