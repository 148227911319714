import React from 'react'
import Banner from "../../Components/Banner/Banner";
import Network from "../../Components/Network/Network";
import Services from "../../Components/Services/Services";
import Contact from "../../Components/Contact/Contact";
import WhyChoose from '../../Components/WhyChoose/WhyChoose';

export default function Home() {
    return (
        <>
            <Banner />
            <Network />
            <WhyChoose />
            <Services />
            <Contact />
        </>
    )
}
