import React from "react";
// import Logo from "../../assets/images/logo-black.svg";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './footer.scss';
import logoWhite from ".././../assets/Images/gmpay-white.svg"
import instagram from ".././../assets/Images/instagram-1-svgrepo-com.svg"
import dribbble from ".././../assets/Images/dribbble-svgrepo-com.svg"
import twitter from ".././../assets/Images/twitter-color-svgrepo-com.svg"
import youtube from ".././../assets/Images/youtube-color-svgrepo-com.svg"
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div
          className="row"
          data-aos="fade-up"
          data-aos-anchor-placement="center-bottom"
        >
          <div className="col-md-4">
            <img src={logoWhite} alt="logo" className='img-fluid footer-logo-img' />
            <p>
              GM PAY INDIA DIGITAL MARKETING PRIVATE LIMITED Shop No.49, Ground Floor, Evershine Mall, Malad, Mumbai, Malad West, Maharashtra, India, 400064 <br /> Copyright © 2024 All rights reserved
            </p>
            <ul className="social_ul">
              <li>
                <a href="#">
                  <img src={instagram} alt="instagram" className='img-fluid social_ul_img' title="Instagram" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src={dribbble} alt="instagram" className='img-fluid social_ul_img' title="dribbble" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src={twitter} alt="instagram" className='img-fluid social_ul_img' title="twitter" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src={youtube} alt="instagram" className='img-fluid social_ul_img' title="youtube" />
                </a>
              </li>
            </ul>
          </div>
          <div className="col-4 col-sm-4 col-md-3">
            <h4>Company</h4>
            <ul className="link_ul">
              <li>
                <a href="#">Services</a>
              </li>
              <li>
                <a href="#">Join Our Network</a>
              </li>
              <li>
                <a href="#">Become a Distributor</a>
              </li>
              <li>
                <a href="#"> Contact us </a>
              </li>

            </ul>
          </div>
          <div className="col-md-3 col-sm-4 col-4">
            <h4>Policies</h4>

            <ul className="link_ul">
              <li>
                <Link to="/terms"> Terms and Conditions</Link>
              </li>
              <li>
                <Link to="/privacy"> Privacy and Policy</Link>
              </li>
              <li>
                <Link to="/Wallet"> Wallet Policy</Link>
              </li>
              <li>
                <Link to="/Grievance"> Grivevance policy</Link>

              </li>
              <li>
                <Link to="/License"> User License Agreement</Link>
              </li>

            </ul>
          </div>
          <div className="col-md-2 col-sm-4 col-4">
            <h4>Links</h4>
            <ul className="link_ul">
              <li>
                <a href="#"> Sign In </a>
              </li>
              <li>
                <a href="#"> Sign Up </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
