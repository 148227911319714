import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "./services.scss";
import ApesIcon from '../../assets/Images/Aeps-1.svg';
import RechargeIcon from '../../assets/Images/Recharge1.svg'; 
import BbpsIcon from '../../assets/Images/bbps.svg';
import AadhaarpayIcon from '../../assets/Images/Aadhaarpay.svg';

export default function MyCarousel() {

  // Configure Owl Carousel settings
  const options = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeOut: 4000,
    animateOut: 'slideOutUp',
    nav: true,
    dots: true,
    margin: 15,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: true,
      },
      600: {
        items: 1,
        nav: false,
      },
      800: {
        items: 2,
        nav: false,
      },
      1000: {
        items: 3,
        nav: true,
        loop: false,
      },
    },
  };

  return (
    <>
      <OwlCarousel className='owl-theme carousel-main-div' {...options}>
        {/* <div class='item'>
          <div className='slider-card'>
            <span>
              <img src={DmtIcon} className='img-fluid icons-img' alt='icon image' />
            </span>

            <h3>Domestic Money Transfer</h3>
            <p className='section-peragrph section-peragrph-mob '>Access opportunities in loan lead generation, making financial assistance more accessible.</p>
          </div>
        </div> */}
        <div class='item'>
          <div className='slider-card'>
            <span>
              <img src={ApesIcon} className='img-fluid icons-img' alt='icon image' />
            </span>

            <h3>Aadhaar Enabled Payment System</h3>
            <p className='section-peragrph section-peragrph-mob'> Effortlessly pay insurance premiums and access general insurance services.</p>
          </div>
        </div>
        <div class='item'>
          <div className='slider-card'>
            <span>
              <img src={RechargeIcon} className='img-fluid icons-img' alt='icon image' />
            </span>

            <h3>Recharges</h3>
            <p className='section-peragrph section-peragrph-mob'>Streamlined collection of loan EMIs for a hassle-free financial experience..</p>
          </div>
        </div>
        <div class='item'>
          <div className='slider-card'>
            <span>
              <img src={BbpsIcon} className='img-fluid icons-img' alt='icon image' />
            </span>

            <h3>All Types of Bill Payments</h3>
            <p className='section-peragrph section-peragrph-mob'>Facilitate various bill payments through our extensive distribution network.</p>
          </div>
        </div>
        <div class='item'>
          <div className='slider-card'>
            <span>
              <img src={AadhaarpayIcon} className='img-fluid icons-img' alt='icon image' />
            </span>

            <h3>Aadhaar Pay</h3>
            <p className='section-peragrph section-peragrph-mob'> Recharge your mobile phones, DTH, and more from our user-friendly platform.</p>
          </div>
        </div>
        {/* <div class='item'>
          <div className='slider-card'>
            <span>
              <img src={Icon6} className='img-fluid icons-img' alt='icon image' />
            </span>

            <h3>Aadhar-enabled Services</h3>
            <p className='section-peragrph section-peragrph-mob'> Utilize Aadhar-enabled systems for cash withdrawals.</p>
          </div>
        </div>
        <div class='item'>
          <div className='slider-card'>
            <span>
              <img src={Icon3} className='img-fluid icons-img' alt='icon image' />
            </span>

            <h3>PAN Card Creation and Correction</h3>
            <p className='section-peragrph section-peragrph-mob'> Facilitate hassle-free PAN card creation and rectification services.</p>
          </div>
        </div> */}
      </OwlCarousel>
    </>
  );
};

