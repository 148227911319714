import { Container, Form, Navbar, Nav, Button, } from 'react-bootstrap';
import './header.scss'
import logo from ".././../assets/Images/gmpay.svg"
import { Link, NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
// import { HashLink } from 'react-router-hash-link';
export default function Header() {

  return (
    <header>
      <div className='container'>
        <Navbar expand="lg"  data-aos="fade-down">
          <Container fluid>
            <Navbar.Brand  as={Link} to="/">
              <img src={logo} alt="logo" className='img-fluid logo' />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="m-auto my-2 my-lg-0"
                style={{ maxHeight: '100px' }}
                navbarScroll
              >
                <Nav.Link as={NavLink} to="/">Home</Nav.Link>
                {/* <Nav.Link as={HashLink} to="/#services">Services</Nav.Link> */}
                <Nav.Link as={HashLink} to="/#service">Services</Nav.Link>
                <Nav.Link as={HashLink} scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} to="/#network">Join Our Network</Nav.Link>
                <Nav.Link as={HashLink} scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })} to="/#distributor">Become a Distributor</Nav.Link>
                <Nav.Link as={HashLink} scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}  to="/#contact">Contact Us</Nav.Link>
              </Nav>
              <Form className="d-flex">
                <a href='https://b2b.gmpayindia.com/login' className="nav-btn">Sign In</a>
                <a href='https://b2b.gmpayindia.com/registration' className="nav-btn">Sign Up</a>
              </Form>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </header>
  );
}
