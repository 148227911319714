import React from 'react'
import "./banner.scss"
import bannerImg from "../../assets/Images/side-big-image.jpg"
export default function Banner() {
    return (
        <section className="Banner_section">
            <div className="container">
                <div className="row Banner_section-row">
                    <div className="col-lg-6 col-md-7" data-aos="flip-left">
                        <h1>
                            Welcome to{" "}
                            <span className="heading-style"> GMPay India </span>
                            Empowering Financial Solutions
                        </h1>
                        <p>
                            GMPay India is your gateway to empowered financial solutions. As a
                            Fintech company, we are committed to uplifting the underprivileged segments of the Indian economy,
                            with a special focus on empowering the migrant population.
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-5 text-center" data-aos="flip-right">
                        <img
                            src={bannerImg}
                            className="img-fluid girl-comunity img-thumbnail"
                            alt="Banner image"
                            style={{ width: "450px" }}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}
