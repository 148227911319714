import React from 'react'
import './whychoose.scss'
import MultySupportImg from "../../assets/Images/multi-services.svg"
import ServiceSupportImg from "../../assets/Images/support-service.svg"
import TeamImg from "../../assets/Images/team.svg"
import EcommerceImg from "../../assets/Images/e-commerce.svg"

export default function WhyChoose() {
    return (
        <section className='WhyChoose-section' id="WhyChoose">
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 title-div text-center' data-aos="zoom-in">
                        <h1 className='section-heading'>
                            WhyChoose
                        </h1>
                        <p className='section-peragrph'>
                            This Portal is a best Multi Recharge Company In India, and provide the multi services with single plateform and support the all client's.
                        </p>
                    </div>
                </div>
            </div>
            <div className='container-fluid'>
                <div className='row align-items-center WhyChoose-section-card-row'>
                    <div className='col-lg-3 col-md-4 col-sm-6 WhyChoose-column' data-aos="zoom-in-down">
                        <div className='choose-card'>
                            <img src={MultySupportImg} class="img-fluid icons-img" alt="icon image" />
                            <h3>multi service with single plateform</h3>
                            <p class="">Our Multi-Services Platform is a flexible, high-density media resource platform with integrated signalling capabilities, which is designed to enable developers to bring new services to market quickly and cost-effectively.</p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 WhyChoose-column' data-aos="zoom-in-down">
                        <div className='choose-card'>
                            <img src={ServiceSupportImg} class="img-fluid icons-img" alt="icon image" />
                            <h3>support service</h3>
                            <p class="">We are providing the best supporting service in India. Customer service and support departments interact with consumers as they need assistance. Learn about the differences between service and support and see how they work.</p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 WhyChoose-column' data-aos="zoom-in-down">
                        <div className='choose-card'>
                            <img src={TeamImg} class="img-fluid icons-img" alt="icon image" />
                            <h3>team</h3>
                            <p class="">Our team is easily accessible to all our customers. We value all our customers and strive to ensure that our products are working optimally. and provides all its customers a range of targeted and specialized support and technical services.</p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 WhyChoose-column' data-aos="zoom-in-down">
                        <div className='choose-card'>
                            <img src={EcommerceImg} class="img-fluid icons-img" alt="icon image" />
                            <h3>E-commerce</h3>
                            <p class="">Looking for a top-class e-commerce development company? Get custom e-commerce development services and robust website designing solutions at competitive prices. Our dedicated web developers are known for delivering ultimate service!</p>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}
