import React from 'react'
import './network.scss'
import merchants1 from "../../assets/Images/cinematic_blur.svg"
import Businessmen from "../../assets/Images/side-big-image1.jpg"
import distributors from "../../assets/Images/mdistributor.svg"

export default function Network() {
    return (
        <section className='network-section' id="network">
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 title-div text-center' data-aos="zoom-in">
                        <h1 className='section-heading'>
                            Join Our Network
                        </h1>
                        <p className='section-peragrph'>
                            Be Part of the financial revolution with GMPay India inclusive network. we Welcome:
                        </p>
                    </div>
                </div>
                <div className='row  align-items-center'>
                    <div className='col-lg-6 col-md-6' data-aos="zoom-in-down">
                        <ul>
                            <li>
                                <div className=''>
                                    <img src={merchants1} className='img-fluid' alt='merchants' />
                                    <span>merchants</span>
                                </div>
                            </li>
                            <li>
                                <div className=''>
                                    <img src={distributors} className='img-fluid' alt='distributors' />
                                    <span>distributors</span>
                                </div>
                            </li>
                            <li>
                                <div className=''>
                                    <img src={distributors} className='img-fluid' alt='Master distributors' />
                                    <span>Master distributors</span>
                                </div>
                            </li>
                        </ul>
                        <p className='network-paras'>
                            Ready to drive financial inclusion? Become<br />
                            a part of GMPay India network and make a <br />
                            significant difference.
                        </p>
                    </div>
                    <div className='col-lg-6 col-md-6' data-aos="zoom-in-up">
                        <img src={Businessmen} className='img-fluid img-thumbnail' alt='Businessmen' />
                    </div>
                </div>
            </div>
        </section>
    )
}
