import React from 'react'
import "./contact.scss"
// import focused from "../../assets/Images/side-bg-image4.jpg"
import focused from "../../assets/Images/side-bg-image4.svg"

export default function Contact() {
    return (
        <section className="Contact_section" id="contact">
            <div className="container">
                <div className="row align-items-center">
                    <div className='col-lg-12 mb-5'>
                        <div className='form-title-div'>
                            <h2 className='section-heading'>contact us</h2>
                            <p className='section-peragrph text-center'> For inquiries or assisance, connect with our dedicated support team.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">

                        <div className='Form_Div'>
                            {/* <div className='form-title-div'>
                                <h2>contact us</h2>
                                <p className='section-peragrph'> For inquiries or assisance, connect with our dedicated support team.</p>
                            </div> */}
                            <div className='form-inputs-div' >
                                <form>
                                    <div className='row'>
                                        <div className='col-sm-6 mb-3'>
                                            <input type='text' name='name' className='form-control' placeholder='Name' />
                                        </div>
                                        <div className='col-sm-6 mb-3'>
                                            <input type='number' name='phone' className='form-control' placeholder='Phone' />
                                        </div>
                                        <div className='col-sm-6 mb-3'>
                                            <input type='text' name='email' className='form-control' placeholder='E-mail' />
                                        </div>
                                        <div className='col-sm-6 mb-3'>
                                            <input type='text' name='add' className='form-control' placeholder='Shop Address' />
                                        </div>
                                        <div className='col-sm-12 mb-3'>
                                            <textarea rows="3" placeholder='Message' ></textarea>
                                        </div>
                                        <div className='col-sm-12 mb-3'>
                                            <button type='button' className='form-control register-btn' > Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 text-center" data-aos="flip-up">
                        <img
                            src={focused}
                            className="img-fluid girl-comunity "
                            alt="community image"
                            style={{ width: "450px", height: "400px", objectFit: "cover" }}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}
